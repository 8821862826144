
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import localStorage from "@/core/services/LocalstorageService";
import { FacilityType } from "@/core/services/responsesModel/facilityExtend";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

// 設施選單model
interface FacilityOptionModel {
  label: string;
  value: string;
}

export default defineComponent({
  setup(props,{emit}) {
    // 空間guid
    const spaceGuid = localStorage.getCurrentSpaces()?.space.guid;
    // formRef
    const formRef = ref<null | HTMLFormElement>(null);
    // 設施選單
    const facilityOption = reactive<Array<FacilityOptionModel>>([]);
    // 規則模式選單
    const ruleModeOptions = [
      {
        value: "FullAmount",
        label: "全額折抵",
      },
      {
        value: "PrepaidAmount",
        label: "預付金額折抵",
      },
      {
        value: "TargetFullAmount",
        label: "指定設施全額折抵",
      },
      {
        value: "TargetTimespan",
        label: "指定設施時間單位折抵",
      },
      {
        value: "TargetSpecialTimespanAmount",
        label: "指定設施特惠價格(若有其他折扣則不會套用此折扣)",
      },
      {
        value: "TargetsPercent",
        label: "指定設施折數優惠",
      },
    ];
    // 優惠項目表單
    const contractItemForm = reactive({
      itemName: "",
      ruleMode: "FullAmount",
      prepaidAmount: null,
      target: "",
      targets: [],
      timespan: null,
      beginEndTime: [],
      amount: null,
      percent: null,
    });
    // 優惠項目表單驗證規則
    const contractItemRules = {
      itemName: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      ruleMode: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "change",
        },
      ],
      beginEndTime: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      prepaidAmount: [
        {
          required: true,
          message: "請填選預付金額",
          trigger: "blur",
        },
      ],
      targets: [
        {
          required: true,
          message: "請填選設施",
          trigger: "change",
        },
      ],
      target: [
        {
          required: true,
          message: "請填選設施",
          trigger: "change",
        },
      ],
      timespan: [
        {
          required: true,
          message: "請填選設施",
          trigger: "blur",
        },
      ],
      amount: [
        {
          required: true,
          message: "請填選優惠價格",
          trigger: "blur",
        },
      ],
      percent: [
        {
          required: true,
          message: "請填選優惠折數",
          trigger: "blur",
        },
      ],
    };

    // 重置表單
    const reseatForm = () => {
      Object.assign(contractItemForm, {
        itemName: "",
        ruleMode: "FullAmount",
        prepaidAmount: null,
        target: "",
        targets: [],
        timespan: null,
        beginEndTime: [],
        amount: null,
        percent: null,
      });
    };

    // 取得空間設施
    const handleGetFacilityBySpace = async () => {
      return await getFacilityBySpace(spaceGuid!);
    };

    // 設置設施下拉選項
    const setFacilityOption = async () => {
      let facilities = await handleGetFacilityBySpace();
      facilities = facilities.filter(
        (facility) => facility.facility.type == FacilityType.Facility
      );
      facilities.forEach((facility) => {
        facilityOption.push({
          label: facility.facility.name,
          value: facility.facility.guid,
        });
      });
    };

    // 取得添加表單
    const getAddForm = () => {
      let addForm = {
        ruleJson: "",
        ruleMode: contractItemForm.ruleMode,
        beginTime: getUtcTimeString(contractItemForm.beginEndTime[0]),
        endTime: getUtcTimeString(contractItemForm.beginEndTime[1]),
      };
      switch (contractItemForm.ruleMode) {
        case "FullAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
          });
          break;
        case "PrepaidAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            maxDiscountAmount: contractItemForm.prepaidAmount,
          });
          break;
        case "TargetFullAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            targets: contractItemForm.targets,
          });
          break;
        case "TargetTimespan":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            targets: contractItemForm.targets,
            maxDiscountTimeSpan: contractItemForm.timespan,
          });
          break;
        case "TargetSpecialTimespanAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            target: contractItemForm.target,
            specialTimespanAmount: contractItemForm.amount,
          });
          break;
        case "TargetsPercent":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            targets: contractItemForm.targets,
            percent: contractItemForm.percent! / 100,
          });
          break;
      }
      return addForm;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          let addForm = getAddForm();
          emit("onAddContractTemplateItem",addForm)
        }
      });
    };

    onMounted(() => {
      let addSeatModal: any = document.getElementById(
        "addContractTemplateItem-modal"
      );
      addSeatModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
      });
    });

    const init = async () => {
      await setFacilityOption();
    };
    init();

    return {
      ruleModeOptions,
      contractItemForm,
      contractItemRules,
      facilityOption,
      formRef,
      submit,
    };
  },
});

<template>
  <div
    class="
      notice
      d-flex
      bg-light-warning
      rounded
      border-warning border border-dashed
      p-6
    "
  >
    <!--begin::Icon-->
    <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
      <inline-svg src="media/icons/duotune/general/gen044.svg" />
    </span>
    <!--end::Icon-->
    <!--begin::Wrapper-->
    <div class="d-flex flex-stack flex-grow-1">
      <!--begin::Content-->
      <div class="fw-bold">
        <!-- <h4 class="text-gray-800 fw-bolder">We need your attention!</h4> -->
        <div class="fs-6 text-gray-600">
          ※可拖動下方列表改變您的規則模式權重<br>
          ※若有新增規則模式，將會被加入最後一項<br>
          ※排序越小權重越高
        </div>
      </div>
      <!--end::Content-->
    </div>
    <!--end::Wrapper-->
  </div>
  <table class="table table-striped">
    <thead class="thead">
      <tr>
        <th scope="col">編號</th>
        <th scope="col">規則模式</th>
      </tr>
    </thead>
    <draggable v-model="ruleModes" tag="tbody">
      <template #item="{ element, index }">
        <tr style="cursor: grab">
          <td>
            {{ index + 1 }}
          </td>
          <td>
            {{ getRuleModeName(element) }}
          </td>
        </tr>
      </template>
    </draggable>
  </table>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref, watch } from "vue";
import draggable from "vuedraggable";
import { AddContractTemplateDiscountListModel } from "../model";

export default defineComponent({
  props: ["addContractTemplateDiscountList"],
  components: {
    draggable,
  },
  setup(props,{emit}) {
    // 規則模式選單
    const ruleModeMap = {
      FullAmount: "全額折抵",
      PrepaidAmount: "預付金額折抵",
      TargetFullAmount: "指定設施全額折抵",
      TargetTimespan: "指定設施時間單位折抵",
      TargetSpecialTimespanAmount:
        "指定設施特惠價格(若有其他折扣則不會套用此折扣)",
      TargetsPercent: "指定設施折數優惠",
    };
    const ruleModes = ref<Array<string>>([]);
    const addContractTemplateDiscountList = reactive<
      Array<AddContractTemplateDiscountListModel>
    >(props.addContractTemplateDiscountList);

    const getRuleModeName = (ruleMode) => {
      return ruleModeMap[ruleMode];
    };

    // 根據新增合約樣板取得所有規則模式
    const setRuleModes = () => {
      ruleModes.value.splice(
        0,
        ruleModes.value.length,
        ...Array.from(
          new Set(addContractTemplateDiscountList.map((o) => o.ruleMode))
        )
      );
    };

    watch(
      props,
      () => {
        setRuleModes();
      },
      { deep: true }
    );
    watch(
      ruleModes,
      () => {
        emit("changeRuleModes",ruleModes)
      },
      { deep: true }
    );

    return {
      ruleModes,
      getRuleModeName,
    };
  },
});
</script>

<style>
</style>
<template>
  <el-form
    @submit.prevent="submit()"
    :model="contractItemForm"
    :rules="contractItemRules"
    ref="formRef"
    class="form"
  >
    <div>
      <!-- 優惠名稱 -->
      <label class="fs-6 fw-bold mb-2">
        <span class="required">優惠名稱</span>
      </label>
      <el-form-item prop="itemName">
        <el-input
          v-model="contractItemForm.itemName"
          placeholder="請輸入名稱"
        ></el-input>
      </el-form-item>
      <!-- 起訖時間 -->
      <label class="fs-6 fw-bold mb-2">
        <span class="required">起訖時間</span>
      </label>
      <el-form-item prop="beginEndTime">
        <el-date-picker
          v-model="contractItemForm.beginEndTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="結束日期"
          style="width: 100%"
        >
        </el-date-picker>
      </el-form-item>
      <!-- 規則模式 -->
      <label class="fs-6 fw-bold mb-2">
        <span class="required">規則模式</span>
      </label>
      <el-form-item>
        <el-select
          style="width: 100%"
          v-model="contractItemForm.ruleMode"
          placeholder="請選擇"
        >
          <el-option
            v-for="item in ruleModeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 參數 -->
      <div v-if="contractItemForm.ruleMode !== 'FullAmount'">
        <label class="fs-6 fw-bold mb-2">
          <span class="required">參數</span>
        </label>
        <!-- PrepaidAmount 預付金額折抵 -->
        <el-form-item
          v-if="contractItemForm.ruleMode == 'PrepaidAmount'"
          prop="prepaidAmount"
        >
          <div class="d-flex">
            <div class="contractItemTitle me-1">預付金額 :</div>
            <el-input
              style="flex: 1"
              type="number"
              v-model="contractItemForm.prepaidAmount"
              placeholder="請填選預付金額"
              :min="1"
            ></el-input>
          </div>
        </el-form-item>
        <!-- TargetFullAmount 指定設施全額折抵 -->
        <div v-if="contractItemForm.ruleMode == 'TargetFullAmount'" class="d-flex mb-2">
          <div class="contractItemTitle me-1">折抵設施 :</div>
          <el-form-item
            prop="targets"
            class="m-0"
            style="flex: 1"
          >
            <el-select
              v-model="contractItemForm.targets"
              multiple
              placeholder="請選擇設施"
              style="width: 100%"
            >
              <el-option
                v-for="facility in facilityOption"
                :key="facility.value"
                :label="facility.label"
                :value="facility.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <!-- TargetTimespan 指定設施時間單位折抵 -->
        <el-form-item
          v-if="contractItemForm.ruleMode == 'TargetTimespan'"
          prop="targets"
        >
          <div class="d-flex mb-1">
            <div class="contractItemTitle me-1">折抵設施 :</div>
            <el-select
              style="width: 100%; flex: 1"
              v-model="contractItemForm.targets"
              placeholder="請選擇設施"
              multiple
            >
              <el-option
                v-for="facility in facilityOption"
                :key="facility.value"
                :label="facility.label"
                :value="facility.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="d-flex">
            <div class="contractItemTitle me-1">時間單位 :</div>
            <el-form-item class="m-0" prop="timespan">
              <el-input
                type="number"
                v-model="contractItemForm.timespan"
                placeholder="請填選時間單位"
                :min="1"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <!-- TargetSpecialTimespanAmount 指定設施特惠價格 -->
        <el-form-item
          v-if="contractItemForm.ruleMode == 'TargetSpecialTimespanAmount'"
          prop="target"
        >
          <div class="d-flex mb-1">
            <div class="contractItemTitle me-1">折抵設施 :</div>
            <el-select
              style="width: 100%; flex: 1"
              v-model="contractItemForm.target"
              placeholder="請選擇設施"
            >
              <el-option
                v-for="facility in facilityOption"
                :key="facility.value"
                :label="facility.label"
                :value="facility.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="d-flex">
            <div class="contractItemTitle me-1">特惠價格 :</div>
            <el-form-item class="m-0" prop="amount">
              <el-input
                type="number"
                v-model="contractItemForm.amount"
                placeholder="請填選特惠價格"
                :min="1"
              ></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <!-- TargetsPercent 指定設施折數優惠 -->
        <div class="mb-1" v-if="contractItemForm.ruleMode == 'TargetsPercent'">
          <div class="d-flex mb-1">
            <div class="contractItemTitle me-1">折抵設施 :</div>
            <el-form-item class="m-0" prop="targets" style="flex: 1">
              <el-select
                v-model="contractItemForm.targets"
                multiple
                placeholder="請選擇設施"
                style="width:100%"
              >
                <el-option
                  v-for="facility in facilityOption"
                  :key="facility.value"
                  :label="facility.label"
                  :value="facility.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div class="d-flex">
            <div class="contractItemTitle me-1">優惠折數 :</div>
            <el-form-item class="m-0" prop="percent" style="flex:1">
              <el-input
                type="number"
                v-model="contractItemForm.percent"
                placeholder="請填選優惠折數"
                :min="1"
                :max="99"
                style="width:60%;"
              >
                <template #append>折(%)</template>
              </el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button class="btn btn-success" type="submit">加入列表</button>
    </div>
  </el-form>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import localStorage from "@/core/services/LocalstorageService";
import { FacilityType } from "@/core/services/responsesModel/facilityExtend";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

// 設施選單model
interface FacilityOptionModel {
  label: string;
  value: string;
}

export default defineComponent({
  setup(props,{emit}) {
    // 空間guid
    const spaceGuid = localStorage.getCurrentSpaces()?.space.guid;
    // formRef
    const formRef = ref<null | HTMLFormElement>(null);
    // 設施選單
    const facilityOption = reactive<Array<FacilityOptionModel>>([]);
    // 規則模式選單
    const ruleModeOptions = [
      {
        value: "FullAmount",
        label: "全額折抵",
      },
      {
        value: "PrepaidAmount",
        label: "預付金額折抵",
      },
      {
        value: "TargetFullAmount",
        label: "指定設施全額折抵",
      },
      {
        value: "TargetTimespan",
        label: "指定設施時間單位折抵",
      },
      {
        value: "TargetSpecialTimespanAmount",
        label: "指定設施特惠價格(若有其他折扣則不會套用此折扣)",
      },
      {
        value: "TargetsPercent",
        label: "指定設施折數優惠",
      },
    ];
    // 優惠項目表單
    const contractItemForm = reactive({
      itemName: "",
      ruleMode: "FullAmount",
      prepaidAmount: null,
      target: "",
      targets: [],
      timespan: null,
      beginEndTime: [],
      amount: null,
      percent: null,
    });
    // 優惠項目表單驗證規則
    const contractItemRules = {
      itemName: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "blur",
        },
      ],
      ruleMode: [
        {
          required: true,
          message: "請填寫名稱",
          trigger: "change",
        },
      ],
      beginEndTime: [
        {
          required: true,
          message: "請填選起訖時間",
          trigger: "blur",
        },
      ],
      prepaidAmount: [
        {
          required: true,
          message: "請填選預付金額",
          trigger: "blur",
        },
      ],
      targets: [
        {
          required: true,
          message: "請填選設施",
          trigger: "change",
        },
      ],
      target: [
        {
          required: true,
          message: "請填選設施",
          trigger: "change",
        },
      ],
      timespan: [
        {
          required: true,
          message: "請填選設施",
          trigger: "blur",
        },
      ],
      amount: [
        {
          required: true,
          message: "請填選優惠價格",
          trigger: "blur",
        },
      ],
      percent: [
        {
          required: true,
          message: "請填選優惠折數",
          trigger: "blur",
        },
      ],
    };

    // 重置表單
    const reseatForm = () => {
      Object.assign(contractItemForm, {
        itemName: "",
        ruleMode: "FullAmount",
        prepaidAmount: null,
        target: "",
        targets: [],
        timespan: null,
        beginEndTime: [],
        amount: null,
        percent: null,
      });
    };

    // 取得空間設施
    const handleGetFacilityBySpace = async () => {
      return await getFacilityBySpace(spaceGuid!);
    };

    // 設置設施下拉選項
    const setFacilityOption = async () => {
      let facilities = await handleGetFacilityBySpace();
      facilities = facilities.filter(
        (facility) => facility.facility.type == FacilityType.Facility
      );
      facilities.forEach((facility) => {
        facilityOption.push({
          label: facility.facility.name,
          value: facility.facility.guid,
        });
      });
    };

    // 取得添加表單
    const getAddForm = () => {
      let addForm = {
        ruleJson: "",
        ruleMode: contractItemForm.ruleMode,
        beginTime: getUtcTimeString(contractItemForm.beginEndTime[0]),
        endTime: getUtcTimeString(contractItemForm.beginEndTime[1]),
      };
      switch (contractItemForm.ruleMode) {
        case "FullAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
          });
          break;
        case "PrepaidAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            maxDiscountAmount: contractItemForm.prepaidAmount,
          });
          break;
        case "TargetFullAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            targets: contractItemForm.targets,
          });
          break;
        case "TargetTimespan":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            targets: contractItemForm.targets,
            maxDiscountTimeSpan: contractItemForm.timespan,
          });
          break;
        case "TargetSpecialTimespanAmount":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            target: contractItemForm.target,
            specialTimespanAmount: contractItemForm.amount,
          });
          break;
        case "TargetsPercent":
          addForm.ruleJson = JSON.stringify({
            name: contractItemForm.itemName,
            targets: contractItemForm.targets,
            percent: contractItemForm.percent! / 100,
          });
          break;
      }
      return addForm;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          let addForm = getAddForm();
          emit("onAddContractTemplateItem",addForm)
        }
      });
    };

    onMounted(() => {
      let addSeatModal: any = document.getElementById(
        "addContractTemplateItem-modal"
      );
      addSeatModal.addEventListener("hidden.bs.modal", function () {
        reseatForm();
      });
    });

    const init = async () => {
      await setFacilityOption();
    };
    init();

    return {
      ruleModeOptions,
      contractItemForm,
      contractItemRules,
      facilityOption,
      formRef,
      submit,
    };
  },
});
</script>

<style scoped>
.contractItemTitle {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
</style>
export interface Contract {
    "guid": string,
    "space": string,
    "name": string,
    "discountPriority": Priority,
    "createTime": string,
    "remark": string,
    "contractDiscounts": Array<ContractDiscount>
    
}

export interface Priority {
    "discountRuleModes": Array<string>
}

export enum DiscountRuleMode {
    None = "None", FullAmount = "FullAmount", PrepaidAmount = "PrepaidAmount", TargetFullAmount = "TargetFullAmount",
    TargetTimespan = "TargetTimespan", TargetSpecialTimespanAmount = "TargetSpecialTimespanAmount", TargetsPercent = "TargetsPercent"
}

export interface ADiscountRule {
    "name": string
}

export interface ContractDiscount {
    "guid": string,
    "contract": string,
    "beginTime": string,
    "endTime": string,
    "ruleMode": DiscountRuleMode,
    "ruleJson": string,
    "createTime": string,
    "discountRule": ADiscountRule
}

export const discountRuleModeMap = {
    "FullAmount": "全額折抵",
    "PrepaidAmount": "預付金額折抵",
    "TargetFullAmount": "指定設施全額折抵",
    "TargetTimespan": "指定設施時間單位折抵",
    "TargetSpecialTimespanAmount": "指定設施特惠價格",
    "TargetsPercent": "指定設施折數優惠"
}

import { defineComponent, reactive, ref, watch } from "vue";
import draggable from "vuedraggable";
import { AddContractTemplateDiscountListModel } from "../model";

export default defineComponent({
  props: ["addContractTemplateDiscountList"],
  components: {
    draggable,
  },
  setup(props,{emit}) {
    // 規則模式選單
    const ruleModeMap = {
      FullAmount: "全額折抵",
      PrepaidAmount: "預付金額折抵",
      TargetFullAmount: "指定設施全額折抵",
      TargetTimespan: "指定設施時間單位折抵",
      TargetSpecialTimespanAmount:
        "指定設施特惠價格(若有其他折扣則不會套用此折扣)",
      TargetsPercent: "指定設施折數優惠",
    };
    const ruleModes = ref<Array<string>>([]);
    const addContractTemplateDiscountList = reactive<
      Array<AddContractTemplateDiscountListModel>
    >(props.addContractTemplateDiscountList);

    const getRuleModeName = (ruleMode) => {
      return ruleModeMap[ruleMode];
    };

    // 根據新增合約樣板取得所有規則模式
    const setRuleModes = () => {
      ruleModes.value.splice(
        0,
        ruleModes.value.length,
        ...Array.from(
          new Set(addContractTemplateDiscountList.map((o) => o.ruleMode))
        )
      );
    };

    watch(
      props,
      () => {
        setRuleModes();
      },
      { deep: true }
    );
    watch(
      ruleModes,
      () => {
        emit("changeRuleModes",ruleModes)
      },
      { deep: true }
    );

    return {
      ruleModes,
      getRuleModeName,
    };
  },
});
